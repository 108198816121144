import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { toKebabCase } from '../helpers'

import * as style from '../styles/intro.module.css'

const Intro = () => {
  return (
    <div className={style.introContainer}>
      <div className={style.introContent}>
      <h1>Hi, I'm Tina,</h1>
      <p ><span className={style.italic}>User Interface</span> and <span className={style.italic}>Experience Designer</span><br /></p>
      <p >based in Berlin.</p>
      <div className={style.introNav}>
      <div className={style.nav}><a href="#work"><span className={style.iconDown}>↓</span>See my work</a></div>
      <div className={style.nav}>
      <Link to="/about">
        Learn about me<span className={style.iconNext}>→</span>
      </Link >
      </div>
      </div>
      <div className={style.cursor}></div>
      </div>
    </div>
  )
}

Intro.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default Intro
