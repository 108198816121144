// extracted by mini-css-extract-plugin
export var coverImage = "intro-module--coverImage--gfZJI";
export var iconDown = "intro-module--iconDown--Bq0uo";
export var iconNext = "intro-module--iconNext--OaIGU";
export var introContainer = "intro-module--introContainer--8yAWZ";
export var introContent = "intro-module--introContent--AWjo0";
export var italic = "intro-module--italic--Z8dzC";
export var meta = "intro-module--meta--S85Hk";
export var nav = "intro-module--nav--dewzS";
export var postContent = "intro-module--postContent--ToMOU";
export var readMore = "intro-module--readMore--ozwxJ";
export var tag = "intro-module--tag--h8i-o";
export var tags = "intro-module--tags--MjnV+";
export var title = "intro-module--title--ql7LP";