import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { toKebabCase } from '../helpers'

import * as style from '../styles/post-grid.module.css'

const PostGrid = ({
  coverImage,
  title,
  path,
  tags,
  html,
}) => {
  return (
      <div className={style.post}
          id='work'>
        <div className={style.postContent}>
          {coverImage && (
            <Link to={path}>
            <Img
              fluid={{...coverImage.childImageSharp.fluid, aspectRatio: 4/3}}
              className={style.coverImage}
            />
            <div className={style.fader}></div>
            </Link> )}
          <h1 className={style.title}>
            <Link to={path}>
              <span>{title}</span>
              <span className={style.iconNext}>→</span>
            </Link>
          </h1>
          </div>
    </div>
  )
}

PostGrid.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default PostGrid
