// extracted by mini-css-extract-plugin
export var coverImage = "post-grid-module--coverImage--BoxQT";
export var fader = "post-grid-module--fader--M-96+";
export var gridWrapper = "post-grid-module--gridWrapper--aFZwk";
export var iconNext = "post-grid-module--iconNext--x3F93";
export var meta = "post-grid-module--meta--CaSmQ";
export var post = "post-grid-module--post--mEtaS";
export var postContent = "post-grid-module--postContent---WrlM";
export var readMore = "post-grid-module--readMore--f5QW1";
export var tag = "post-grid-module--tag--khHeb";
export var tags = "post-grid-module--tags--YQDUF";
export var title = "post-grid-module--title---psl8";